
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ICustomer } from "@/types/customer";
import CustomerStaffList from "@/components/admin/list/CustomerStaffList.vue";

@Component({
  components: { CustomerStaffList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Action("customer/adminGet")
  public getCustomer!: (id: number) => void;

  @Getter("customer/single")
  public customer!: ICustomer;

  @Mutation("customer/clear")
  public clear!: () => void;

  // fields
  public customer_id = 0;
  public disp = false;

  public items: { text: string; icon: string; to: string }[] = [];

  public async created() {
    this.customer_id = Number(this.$route.params.customer_id);

    this.items = [
      {
        text: "詳細",
        icon: "mdi-card-account-details-outline",
        to: `/admin/customer/${this.customer_id}`
      },
      {
        text: "支払条件",
        icon: "mdi-cash-clock",
        to: `/admin/customer/${this.customer_id}/payment_term`
      },
      {
        text: "顧客担当者",
        icon: "mdi-account",
        to: `/admin/customer/${this.customer_id}/customer_staff`
      },
      {
        text: "支店",
        icon: "mdi-warehouse",
        to: `/admin/customer/${this.customer_id}/branch`
      }
    ];

    this.clear();
    await Promise.all([this.getCustomer(this.customer_id)]);
    this.disp = true;
  }
}
